import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styledComponents'
import { ConnectedComponent } from 'react-redux'

import { CardInterface, CardStackViewInterface } from 'types'
import { cardMap } from 'components/_cards'
import { Card } from 'components/_utility'
import { getCardHeaderFromCard } from 'utility'

export interface Props {
  className?: string
  view: CardStackViewInterface
  cards: CardInterface[]
  isToolbarMode: boolean
  submitForm: () => {}
}

const mapCardsToComponents = (
  cards: CardInterface[],
  viewId: string,
  submitForm: any
) => {
  return cards.map((card: CardInterface, i: number) => {
    const props: any = {
      ...card.props,
      _width: 100,
      _id: card._id,
      mb: i < cards.length - 1 ? 9 : 0,
      header: getCardHeaderFromCard(card),
      preset: card.preset
    }
    const CardComponent: ConnectedComponent<any, any> = cardMap[card.template]
    if (card.template === 'form') {
      props.onSubmit = submitForm
    }
    return (
      <CSSTransition
        key={`${viewId}-${i}`}
        classNames="card"
        timeout={180 * i}
        appear={true}
        in={true}
      >
        <CardComponent {...props} />
      </CSSTransition>
    )
  })
}

const Component = ({ className, view, cards, submitForm }: Props) => (
  <div className={className}>
    {mapCardsToComponents(cards, view._id, submitForm)}
  </div>
)

export const CardStackDisplay = styled(Component)`
  ${p =>
    !p.isToolbarMode &&
    css`
      padding: 0 12px 12px;
    `}

  ${p =>
    p.isToolbarMode &&
    css`
      > ${Card} {
        box-shadow: 0 4px 72px rgba(0, 0, 0, 0.12);
      }
    `}

  .card-appear {
    position: relative;
    top: 12px;
    opacity: 0;
  }

  .card-enter-done {
    position: relative;
    top: 0px;
    transition: top 300ms ease-out, opacity 270ms;
    opacity: 1;
  }
`
